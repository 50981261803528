import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { AccordioContainer } from "./styles";

type AccordionProps = {
  i: number;
  expanded: number;
  setExpanded: (val: number | boolean) => void;
  accordionTitle: string;
  accordionContent: string;
};

export const Accordion: React.FC<AccordionProps> = ({
  i,
  expanded,
  setExpanded,
  accordionTitle,
  accordionContent,
}) => {
  const isOpen = i === expanded;
  return (
    <AccordioContainer>
      <motion.header
        initial={false}
        animate={{
          background: isOpen
            ? "linear-gradient(to right,var(--tg-theme-primary),var(--tg-theme-primary-pallet))"
            : "#fff",
          color: isOpen ? "#141410" : "#141410",
        }}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <h4>
          {accordionTitle}
          {isOpen ? <MdKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
        </h4>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <p dangerouslySetInnerHTML={{ __html: accordionContent }}></p>
          </motion.section>
        )}
      </AnimatePresence>
    </AccordioContainer>
  );
};
