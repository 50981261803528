import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";

export const FaqHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
 
  @media screen and (max-width: ${tokens.breakpoints.s}) {
    text-align:center;
    justify-content:center;
    align-items:center;
    margin-top:50px;
  }

  h2 ,h2 span{
    margin-top: clamp(25px, 3vw, 40px);
    font-weight:900;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

     span {
      color:var(--tg-theme-primary);
     }
  }

  p{
    margin:0;
  }
  
  hr.bar{
    width:75px;
    max-width:100%;
    height:10px;
    background:transparent;
    border:none;
    color:#fff !important;
    opacity:1;
    margin-left: 0;
    position:relative;
  

  &::before{
      content: '';
      height: 7px;
      width: 75px;
      background-color: currentColor;
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: scaleX(-1);
      -webkit-clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
      clip-path: polygon(0 0, calc(100% - 5px) 0, 100% 100%, 100% 100%, 0 100%);
    }

  }


}

`;

export const CustomContainer = styled(SectionContainer)`
 
  padding: 16px;
  flex-direction: column;
  margin: 0 0 100px 0;
`;
