import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";

export const FeedbacksContainer = styled(SectionContainer)`
  flex-direction: column;
  align-items: center;
  gap: 52px;
  max-width: unset;
  padding: 100px;
 
  @media screen and (max-width: ${tokens.breakpoints.s}) {
    text-align:center;
    justify-content:center;
    align-items:center;
    margin-top:50px;
  }

  .carousel-root, .carousel  {
    height: 100%;
  }

  img {
    max-width: 70%;
    height: 100%;
    object-fit: contain;

    @media screen and (max-width: ${tokens.breakpoints.s}) {
      max-width: 100%;
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    padding: 16px;
  }

    
  h2 ,h2 span{
    margin-top: clamp(25px, 3vw, 40px);
    font-weight:900;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

     span {
      color:var(--tg-theme-primary);
     }

   
  }
`;
