import * as React from "react";
import { useState } from "react";
import { Accordion } from "../Accordion";
import { FaqHeader, CustomContainer } from "./styles";
import { faqItems } from "./constants";
import { FAQSchema } from "../FAQSchema";

export const FAQ: any = () => {
  const [expanded, setExpanded] = useState<false | number>(false);

  const acoordions = faqItems.map((item, index) => (
    <Accordion
      accordionContent={item.answer}
      accordionTitle={item.question}
      key={index}
      i={index}
      expanded={expanded as number}
      setExpanded={setExpanded as any}
    />
  ));

  return (
    <>
      <FAQSchema mainEntity={faqItems as any} />
      <CustomContainer>
        <FaqHeader>
          <h2>
            Perguntas <span>Frequentes</span>
          </h2>
          <p>Abaixo respondemos algumas das perguntas mais frequentes.</p>
          <hr className="bar" />
        </FaqHeader>
        {acoordions}
      </CustomContainer>
    </>
  );
};
