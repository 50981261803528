import { SectionFeedFaqContainer } from "./styles";
import { FeedbacksSection } from "../FeedbacksSection";
import { FAQ } from "../FAQ";

export const SectionFeedFaq = () => {
  return (
    <SectionFeedFaqContainer>
      <FeedbacksSection />
      <FAQ/>
     
    </SectionFeedFaqContainer>
  );
};
