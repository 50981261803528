import React from "react";
import Image from "next/image";
import { Carousel } from "react-responsive-carousel";

import { FeedbacksContainer } from "./styles";
import { CustomAcquireButton } from "../CustomAcquireButton";

import feedbackImage from "public/assets/img/feedbacks/feedback-01.webp";
import feedbackImage2 from "public/assets/img/feedbacks/feedback-02.webp";
import feedbackImage3 from "public/assets/img/feedbacks/feedback-03.webp";

export const FeedbacksSection = () => {
  return (
    <FeedbacksContainer>
      <h2>
        SERVIÇOS<span> CONCLUÍDOS</span>
      </h2>
      <Carousel showArrows={true} showThumbs={false}>
        <Image src={feedbackImage} alt="feedback" />
        <Image src={feedbackImage2} alt="feedback" />
        <Image src={feedbackImage3} alt="feedback" />
      </Carousel>

      <CustomAcquireButton
          link="https://discord.com/invite/B9YM7EAqCD" >
          Ver mais
        </CustomAcquireButton>

    </FeedbacksContainer>
  );
};
