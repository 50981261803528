export const faqItems = [
  {
    question: "A ELOJOB RISE É SEGURA E CONFIÁVEL?",
    answer:
      "Sim, sempre trabalhamos com qualidade, eficiência e proteção. Na parte de avaliações do nosso site e instagram, temos vários depoimentos de clientes sobre os serviços que fornecemos, contando também com uma vasta referência no mercado de Elojob.",
  },
  {
    question: "O QUE É ELOJOB?",
    answer:
      "Primeiramente, nós sabemos que os jogadores se frustram diariamente por não conseguirem vencer a maioria dos jogos. Mesmo desempenhando bem, são afundados por times ruins que não colaboram para a vitória, ou até mesmo por aliados trolls que fazem de tudo para acabar com o jogo. Além disso, as derrotas seguidas acabam negativando o MMR da conta, fazendo-a perder mais PdL’s do que ganha, e por consequência isso acaba dificultando ainda mais a subida de Elo. <br/> <br/> Ciente dessas questões, a prática de “Elo Job” surge a partir do objetivo em tornar a sua experiência nas filas ranqueadas mais leves. O serviço consiste em um dos nossos jogadores profissionais jogar na sua conta, permitindo que você alcance o seu verdadeiro Elo sem precisar se estressar. Existe também a modalidade “Duo Boost”, onde você poderá ir duo com um desses jogadores.",
  },
  {
    question: "SE FOR DUO BOOST, POSSO ESCOLHER OS HORÁRIOS DOS JOGOS?",
    answer:
      "Após anos de experiência com esses serviços, compreendemos a existência de dois perfis mais comuns de clientes de Duo Boost: <br /> <br /> <b>1-</b> Aquele que prefere essa modalidade porque não gosta de fornecer suas informações de login e quer participar ativamente da escalada de Elo.<br /> <b>2-</b> Aquele que deseja  ir duo invés de jogar sozinho, tendo a companhia de alguém que realmente jogue tryhard, dando tudo de si para vencer. <br /> <br /> Seja qual for o seu perfil, nós compreendemos e estamos disponíveis para oferecer a melhor solução para você, podendo escolher o horário dos jogos, seja de manhã, tarde, noite, madrugada, assim como os dias da semana. Temos uma equipe preparada para te atender.",
  },
  {
    question: "QUAL O DIFERENCIAL DA ELOJOB RISE?",
    answer:
      "Desde a fundação do Elojob Rise, temos nos comprometido a trabalhar exclusivamente com os jogadores mais habilidosos da fila ranqueada, mantendo consistentemente um alto nível de desempenho dos Boosters, que estão no elo: Grão-Mestre/Desafiante. Atualmente, contamos com uma equipe de mais de 100 jogadores cuja habilidade continua excepcionalmente elevada, graças ao nosso criterioso processo seletivo e treinamento contínuo.<br/><br/> A integridade da sua conta é a nossa prioridade máxima. Além disso, oferecemos a oportunidade de assistir às partidas ao vivo no Discord, onde você também pode interagir e tirar dúvidas com o seu Booster. Dessa forma, você poderá aprender e evoluir durante o processo, proporcionando uma experiência enriquecedora.",
  },
  {
    question: "EXISTE CHANCE DE SUSPENSÃO ADQUIRINDO UM DOS SERVIÇOS?",
    answer:
      "Não, a chance é <span class='enforce'>ZERO</span>. Elojob deixou de dar Ban faz tempo, e nós aqui da Elojob Rise reforçamos a sua segurança utilizando VPN, sendo assim, nós colocamos os nossos Boosters como se estivessem jogando no estado em que você mora, portanto, a Riot nunca saberá que outra pessoa em outro estado estará jogando. Esse segredo, é da Elojob Rise.",
  },
  {
    question: "COMO FUNCIONA O PAGAMENTO?",
    answer:
      "O pagamento deverá ser realizado e confirmado para que possamos encaminhar o pedido para o Booster responsável. Além do histórico positivo da Elojob Rise que já se consolidou no mercado de boosting, caso o prazo de 72 horas úteis tenha sido passado, você pode recuperar seu dinheiro através do suporte das empresas referentes: PayPal, PicPay e MercadoPago, caso entenda assim necessário.",
  },
  {
    question: "O QUE ACONTECE SE PASSAR DO PRAZO DE ENTREGA COMBINADO?",
    answer:
      "Antes de um jogador fazer parte de nossa equipe ele passa por um árduo processo seletivo onde filtramos somente os mais interessados, habilidosos e responsáveis da fila ranqueada. Em raríssimas ocasiões este prazo é ultrapassado, quando isso acontece entraremos em contato informando o novo prazo de entrega (máximo de 7 dias úteis de acréscimo ao prazo original) e dependendo do motivo que ocasionou o atraso oferecemos também um bônus de vitórias em sua conta ou descontos em serviços.",
  },
  {
    question: "COMO SEI QUE MINHA CONTA NÃO SERÁ ROUBADA?",
    answer:
      "Somos uma empresa íntegra e todos os nossos funcionários estão de acordo com os valores que impomos, desta forma isso está fora de cogitação! Além disso, precisaríamos ter acesso ao seu e-mail cadastrado na conta Riot para realizar a troca de senha ou de qualquer outra informação, e no ato do pedido não solicitamos esta informação, pedimos apenas usuário e senha da conta Riot. Sempre recomendamos alterar a senha e colocar uma provisória durante o período do serviço para maior segurança.",
  },
  {
    question: "QUEM FICARÁ SABENDO QUE EU COMPREI O SERVIÇO?",
    answer:
      "Ninguém! Porque durante as partidas, nossos Boosters estarão com ''CHAT OFF'', no qual utilizam um código no CMD, que a tarefa exata é a desativação todas as funções de CHAT do jogo, sendo assim a sua conta vai parecer que está offline para todas as pessoas da sua lista de amigos. Legal né?",
  },
];
