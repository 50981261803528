import styled from "styled-components";

export const AccordioContainer = styled.div`
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .content-placeholder {
    padding: 20px;
    transform-origin: top center;
  }

  header {
    border-radius: 5px;
    color: black;
    cursor: pointer;
    height: auto;
    margin-bottom: 20px;
    font-weight: 500;
    padding: 12px;
    display: flex;

    h4 {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    p,
    h4 {
      color: black;
      font-size: 18px;

      .enforce {
        color: red;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
      }
    }
  }

  section {
    overflow: hidden;

    p {
      background-color: white;
      color: black;
      padding: 16px;

      .enforce {
        color: red;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 24px;
      }
    }
  }

  @media (max-width: 600px) {
    .content-placeholder {
      padding-left: 20px;
    }

    .header .word {
      height: 30px;
    }

    .word {
      height: 14px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .paragraph {
      margin-bottom: 20px;
    }
  }
`;
